import React, { useState }  from 'react';
import Tesseract from 'tesseract.js';

function ReadImage() {
    const [file, setFile] = useState();
    const [text, setText] = useState();

    function handleChange(e) {
        setText();
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    function handleClick() {

        Tesseract.recognize(
            file,'eng',
            { 
              logger: m => console.log("progress: " + m) 
            }
          )
          .catch (err => {
            console.error("error:" + err);
          })
          .then(result => {
            // Get Confidence score
            let confidence = result.confidence
            console.log("confidence: " + confidence);
            // Get full output
            let text = result.data.text
            console.log("fetched data: ", text);
            text = text.replace(/ \n/g, '\n');
            let matchedDate;

            //Date Format
            const dateRegexFormat = /\d{2}\s*\.\s*\d{2}\s*\.\s*\d{4}/;
            matchedDate = text.match(dateRegexFormat)[0].replace(/\s*/g, "");

            const regex = /\d{1,3},\d{2}/g;
            let lastMatch;
            let match;

            while ((match = regex.exec(text)) !== null) {
                lastMatch = match[0];
            }
        
            setText("Date: " + matchedDate + " cost: " + lastMatch);
          })
    }

    return (
        <div class="uk-container">
            <div class="uk-text-left">
                <ul>
                    <li>This project is to demonstrate reading text from the image.</li>
                    <li>It is to read the date and total cost of the receipt.</li>
                    <li>Upload any of the European grocery shopping bill for demo.</li>
                </ul>
            </div>
            <div class="uk-card uk-card-default uk-card-hover">
                <h2> Image to text converter</h2>
                <input type="file" onChange={handleChange}></input>
                <div class="uk-card-media-top">
                    <img src={file} alt="" style={{width: "30%", maxWidth: "25%"}}/>
                </div>
                <button onClick={handleClick}>Convert to text</button>
                <div>
                    <p>{text}</p>
                </div>
            </div>
        </div>
        
    );
}

export default ReadImage;